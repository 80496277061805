import 'rc-tree/assets/index.css'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import Tree from 'rc-tree'
import { sortBy, omit, uniqBy } from 'lodash'
import TerrainIcon from '@mui/icons-material/Terrain'
import RoomIcon from '@mui/icons-material/Room'
import { createUseStyles } from 'react-jss'
import useProgressiveDataLoader from '../../hooks/useProgressiveDataLoader'
import {
  GET_ASSET_ZONES_SITES_LOOKUP_QUERY,
} from '../../constants/queries/lookup'
import CircularProgress from "@mui/material/CircularProgress";
import { FormGroup, Input, Label } from "reactstrap";


const useStyles = createUseStyles({
  iconContainer: {
    marginTop: '-3px',
  },
  selectAllInput:{
    marginTop: '.1rem'
  }
})

// eslint-disable-next-line import/prefer-default-export
function buildTree(allSites, classes) {
  const _treeData = []
  const _allKeys = []
  for (const site of allSites) {
    const _siteKey = JSON.stringify({
      siteId: site.id,
    })
    const node = {
      key: _siteKey,
      title: site.name,
      type: 'SITE',
      children: [],
      checkable: true,
      selectable: false,
      // isChecked: true,
      icon: () => (
        <div className={classes.iconContainer}><TerrainIcon fontSize='small'/>
        </div>),
    }
    _allKeys.push(_siteKey)
    if (site.assets && Array.isArray(site.assets)) {
      for (const asset of site.assets) {
        const _assetKey = JSON.stringify({
          assetId: asset.id,
        })
        node.children.push({
          key: _assetKey,
          title: asset.name,
          type: 'ASSET',
          isLeaf: true,
          checkable: true,
          selectable: false,
          // isChecked: true,
          //  className: selectedFile && selectedFile.id && selectedFile.id === fileItem.id ?  'active-selected-node' : 'leaf-node',
          //  selectable: true,
          icon: () => (
            <div className={classes.iconContainer}><RoomIcon fontSize='small'/>
            </div>),
          siteId: site.id,
        })
        _allKeys.push(_assetKey)
      }
    }
    _treeData.push(node)
  }
  return { _treeData, _allKeys }
}


function reducer(currentState, newState) {
  return { ...currentState, ...newState }
}

function useSiteZoneAssetsTreeSelector({ companyId, assetId, type, setFieldValue, siteFieldName, zoneFieldName, name, classes }) {
  const treeRef = useRef()

  const { fetchData, data, allDataLoaded } = useProgressiveDataLoader({
    query: GET_ASSET_ZONES_SITES_LOOKUP_QUERY,
    isImmediate: false,
  })

  const [{ treeData, allSites, autoExpandParent, expandedKeys, checkedKeys }, setState] = useReducer(reducer, {
    treeData: [],
    allSites: [],
    autoExpandParent: true,
    expandedKeys: undefined,
    checkedKeys: undefined,
  })

  useEffect(() => {
    if (companyId) {
      fetchData({
        variables: {
          page: 1,
          companyId,
        },
      })
    }
  }, [companyId, fetchData])


  useEffect(() => {
    if (data) {
      const __allSitesMap = new Map();
      const _data = type ? data.filter(item => item.type === type):  [...data];
      // eslint-disable-next-line no-restricted-syntax
      for (const zone of _data) {
        if (zone.site?.id) {
          if (!__allSitesMap.has(zone.site.id)) {
            __allSitesMap.set(zone.site.id, {
              ...zone.site,
              assets: [omit(zone, 'site')],
            })
          } else {
            const _zoneRef = __allSitesMap.get(zone.site.id)
            _zoneRef.assets.push(omit(zone, 'site'))
          }
        }
      }
      for (const [key, value] of __allSitesMap.entries()) {
        __allSitesMap.set(key, { ...value, assets: sortBy(uniqBy(value.assets, 'id'), 'name') })
      }
      const __allSites = sortBy([...__allSitesMap.values()], 'name')
      setState({
        allSites: __allSites,
      })
    }
  }, [data, type])

  const selectByKeys = useCallback((_checkedKeys) => {
    const siteIds = []
    const zoneIds = [];
    const assetIds = [];
    for (const _checkedKey of _checkedKeys) {
      const keyObj = JSON.parse(_checkedKey)
      if (keyObj.assetId) {
        assetIds.push(keyObj.assetId)
      } else if (keyObj.siteId) {
        siteIds.push(keyObj.siteId)
      } else if (keyObj.zoneId) {
        zoneIds.push(keyObj.zoneId)
      }
    }
    setFieldValue( siteFieldName ?? 'siteId', siteIds);
    setFieldValue( zoneFieldName ?? 'zoneId', zoneIds);
    setFieldValue( name ?? 'assetId', assetIds)
    // eslint-disable-next-line
  }, [setFieldValue])

  useEffect(() => {
    if (allSites) {
      const { _treeData, _allKeys } = buildTree(allSites, classes)
      setState({
        treeData: _treeData,
        expandedKeys: _allKeys,
      })
    }
    // eslint-disable-next-line
  }, [allSites, selectByKeys])


  useEffect(() => {
    if (treeData && treeData.length > 0) {
      const _checkedKeys = []
      if (assetId && Array.isArray(assetId)) {
        for (const id of assetId) {
          _checkedKeys.push(JSON.stringify({ assetId: id }))
        }
      }
      setState({
        checkedKeys: _checkedKeys,
      })
    }
  }, [assetId, treeData])

  const onCheck = useCallback((_checkedKeys) => {
    selectByKeys(_checkedKeys)
    // eslint-disable-next-line
  }, [treeData, selectByKeys])

  const onExpand = useCallback((_expandedKeys) => {
    setState({
      expandedKeys: _expandedKeys,
    })
    // eslint-disable-next-line
  }, [])

  const onSelectDeselectAll = useCallback((e)=>{
    if (e.target.checked === true){
      if (treeData && treeData.length > 0) {
        const treemap = new Map();
        treeData.forEach((item=>{
          treemap.set(item.key, true);
          if (item.children){
            item.children.forEach((innerItem=>{
              treemap.set(innerItem.key, true);
            }));
          }
        }));
        const _checkedKeys = [...treemap.keys()];
        setState({
          checkedKeys: _checkedKeys
        });
        selectByKeys(_checkedKeys)
      }
    } else {
      setState({
        checkedKeys: []
      });
      selectByKeys([])
    }
  },[treeData, selectByKeys]);

  return {
    treeRef,
    treeData,
    expandedKeys,
    checkedKeys,
    onCheck,
    onExpand,
    autoExpandParent,
    allDataLoaded,
    onSelectDeselectAll
  }

}

const SiteZonesAssetsTreeSelector = React.memo(function({ companyId, siteFieldName, zoneFieldName, name, assetId, siteId, zoneId, setFieldValue, type, height }) {
  const classes = useStyles()
  const { treeRef, treeData, expandedKeys, checkedKeys, onCheck, onExpand, allDataLoaded, onSelectDeselectAll } = useSiteZoneAssetsTreeSelector({
    companyId,
    siteId,
    zoneId,
    assetId,
    setFieldValue,
    classes,
    type,
    siteFieldName,
    zoneFieldName,
    name
  })

  if (!allDataLoaded) {
    return <div>
      <CircularProgress size={20} />
    </div>
  }

  return (<div>
    {treeData && treeData.length > 0 && (
      <FormGroup check>
        <Label check>
          <Input onChange={onSelectDeselectAll} type="checkbox" className={classes.selectAllInput} />
          Select/De-Select all
        </Label>
      </FormGroup>
    )}
    <Tree
      ref={treeRef}
      checkable
      multiple
      defaultCheckedAll
      expandedKeys={expandedKeys}
      checkedKeys={checkedKeys}
      treeData={treeData}
      showIcon
      onCheck={onCheck}
      onExpand={onExpand}
      height={height ?? 400 }
    />
  </div>)
})


export default SiteZonesAssetsTreeSelector
