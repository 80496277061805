import React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

const menuItems = [{
    label: 'Last 1 hour',
    name: 'now-1h',
}, {
  label: 'Last 12 hours',
  name: 'now-12h'
},{
  label: 'Last 24 hours',
  name: 'now-24h'
}, {
  label: 'Last 3 days',
  name: 'now-3d'
},{
  label: 'Last 7 days',
  name: 'now-7d'
}, {
  label: 'Last 1 month',
  name: 'now-1M'
}, {
  label: 'Last 2 month',
  name: 'now-2M'
} , {
  label: 'Last 3 month',
  name: 'now-3M'
}, {
  label: 'Last 6 month',
  name: 'now-6M'
},  {
  label: 'Last 9 month',
  name: 'now-9M'
}, {
  label: 'Last 1 year',
  name: 'now-1Y'
}];

const TinyReportDateSelector = React.memo(({isOpen, toggle, onSelect, end}) => {


  return (
    <Dropdown group isOpen={isOpen} size="sm" toggle={toggle}>
      <DropdownToggle style={{cursor: 'pointer'}} tag="span"
                      data-toggle="dropdown"
                      aria-expanded={isOpen}>
        <i className="icon bars"/>
      </DropdownToggle>
      <DropdownMenu end={end}>
        {menuItems.map((menu, index) => (
          <DropdownItem key={`menu-${index}`}
                        onClick={() => onSelect(menu)}>{menu.label}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
})


export default TinyReportDateSelector;
