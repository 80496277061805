import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import {
  groupBy,
  isUndefined, keyBy,
  mean,
  orderBy,
  round,
  sortBy,
  upperFirst,
} from 'lodash';
import 'react-widgets/styles.css';
import 'react-toggle/style.css';
import DatePicker from 'react-widgets/DatePicker';
import {Formik, useFormik} from 'formik';
import {format, subDays} from 'date-fns';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  FormFeedback,
  FormGroup,
  Progress,
} from 'reactstrap';
import {useLazyQuery, useMutation} from '@apollo/client';
import * as Yup from 'yup';
import {createUseStyles} from 'react-jss';
import GlobalContext from '../../providers/GlobalContext';
import TinySpinner from '../TinySpinner';
import SitesTreeSelector from '../selectors/SitesTreeSelector';
import SiteZonesTreeSelector from '../selectors/SiteZonesTreeSelector';
import SiteZonesAssetsTreeSelector
  from '../selectors/SiteZonesAssetsTreeSelector';
import timeHelper from '../../helpers/timeHelper';
import {isDate} from 'lodash';
import {
  CREATE_CUSTOM_REPORT_MUTATION
} from '../../constants/mutations/dashboard';
import ExportReportSelector from '../selectors/ExportReportSelector';
import {
  GET_ASSET_RAW_TELEMETRY_QUERY, GET_ASSET_VEHICLE_RAW_TELEMETRY_QUERY,
  GET_INCIDENTS_CSV_QUERY,
  GET_SITE_ASSET_SUMMARY_PERFORMANCE_TELEMETRY_QUERY,
  GET_SITE_PERFORMANCE_CSV_QUERY,
  GET_SITE_SUMMARY_TELEMETRY_QUERY,
} from '../../constants/queries/dashboard';
import CsvDownLoader from '../../helpers/CsvDownloader';
import commonReducer from '../../common/commonReducer';
import {v1} from 'uuid';
import {toast} from 'react-toastify';
import {mergePerformances} from '../../helpers/performanceHelper';
import {useProgressiveDataLoader} from '../../hooks';
import {transformIncidentCsvRow} from '../../helpers/csvReportHelper';
import {isValidNumber} from "../../helpers/commonHelper";
import TimeIntervalSelector from "../selectors/TimeIntervalSelector";

const dateToEpochString = (date) =>
  Math.round(date.getTime() / 1000).toString();

const assetReportTypes = [
  'RAW_TELEMETRY_CSV',
  'RAW_VEHICLE_TELEMETRY_CSV',
  'SITE_SUMMARY',
  'ASSET_SUMMARY',
  'ASSET_INCIDENT',
  'ASSET_JOURNEY',
  'ASSET_PERFORMANCE',
  'ASSET_PERFORMANCE_HISTORY',
  'ASSET_PERFORMANCE_CSV',
];
const siteReportType = [
  'SITE_PERFORMANCE',
  'SITE_COMPLIANCE_INTRADAY',
  'SITE_COMPLIANCE_WEEKLY',
];
const zoneReportTypes = [
  'ZONE_PERFORMANCE',
  'SITE_SUMMARY_CSV',
  'SITE_PERFORMANCE_CSV',
  'ASSET_SUMMARY_PERFORMANCE_CSV',
  'ZONE_COMPLIANCE_WEEKLY',
  'INCIDENTS',
];

const ValidationSchema = Yup.object().shape({
  reportType: Yup.string().required('Report Type is required'),
  companyId: Yup.number().required('Company is required'),
  from: Yup.number().required('From date is invalid'),
  to: Yup.number().required('To date is invalid'),
  interval: Yup.string().when('reportType', {
    is: (reportType) => reportType === 'RAW_VEHICLE_TELEMETRY_CSV',
    then: Yup.string().required('Interval is required'),
    otherwise: Yup.string().nullable(),
  }),
}, [['reportType', 'interval']]);

const useStyles = createUseStyles({
  submissionCompleteContainer: {
    margin: '30px',
    textAlign: 'center',
    animation: 'animate-base-container 850ms forwards',
  },
  blueText: {
    color: '#1870a7',
  },
  progressContainer: {
    width: '100%',
  },
  fileDownloadLink: {
    fontSize: '3em',
  },
});

const __fieldNames = [
  'optimalBelowLowCount',
  'optimalCount',
  'optimalAboveHighCount',
  'totalCount',
  'incidentBelowLowCount',
  'incidentAboveHighCount',
  'optimalBelowLowPercentage',
];

function prepareAssetSummaryPerformanceData(data) {
  const resultData = [];
  const groupedData = groupBy(
    data,
    (item) =>
      `${item.siteName}-${item.zoneName}-${item.assetName}-${item.propertyGroupName}`,
  );
  Object.keys(groupedData).forEach((keyName) => {
    const newRow = {};
    __fieldNames.forEach((field) => {
      newRow[field] = 0;
    });
    const minArray = [];
    const maxArray = [];
    const avgArray = [];
    groupedData[keyName].forEach((item, index) => {
      if (index === 0) {
        newRow.siteName = item.siteName;
        newRow.zoneName = item.zoneName;
        newRow.assetName = item.assetName;
        newRow.unit = item.unit;
        newRow.propertyGroupName = item.propertyGroupName;
      }
      if (isValidNumber(item.min)) {
        minArray.push(item.min);
      }
      if (isValidNumber(item.max)) {
        maxArray.push(item.max);
      }
      if (isValidNumber(item.avg)) {
        avgArray.push(item.avg);
      }
      __fieldNames.forEach((field) => {
        if (isValidNumber(item[field])) {
          newRow[field] += item[field];
        }
      });
    });

    newRow.optimalPercentage = round(
      (newRow.optimalCount / newRow.totalCount) * 100,
      1,
    );
    newRow.optimalBelowLowPercentage = round(
      (newRow.optimalBelowLowCount / newRow.totalCount) * 100,
      1,
    );
    newRow.optimalAboveHighPercentage = round(
      (newRow.optimalAboveHighCount / newRow.totalCount) * 100,
      1,
    );
    newRow.incidentBelowLowPercentage = round(
      (newRow.incidentBelowLowCount / newRow.totalCount) * 100,
      1,
    );
    newRow.incidentAboveHighPercentage = round(
      (newRow.incidentAboveHighCount / newRow.totalCount) * 100,
      1,
    );

    if (isNaN(newRow.optimalPercentage)) {
      newRow.optimalPercentage = 0;
    }
    if (isNaN(newRow.optimalBelowLowPercentage)) {
      newRow.optimalBelowLowPercentage = 0;
    }
    if (isNaN(newRow.optimalAboveHighPercentage)) {
      newRow.optimalAboveHighPercentage = 0;
    }
    if (isNaN(newRow.incidentBelowLowPercentage)) {
      newRow.incidentBelowLowPercentage = 0;
    }
    if (isNaN(newRow.incidentAboveHighPercentage)) {
      newRow.incidentAboveHighPercentage = 0;
    }

    newRow.min = Math.min(...minArray);
    newRow.max = Math.max(...maxArray);
    newRow.avg = round(mean(avgArray), 1);

    resultData.push(newRow);
  });

  return resultData;
}

function prepareAssetSummaryPerformanceDataBySiteName(data) {
  const resultData = [];
  const groupedData = groupBy(data, 'siteName');
  const keys = Object.keys(groupedData).sort();
  keys.forEach((keyName) => {
    const newRow = {};
    __fieldNames.forEach((field) => {
      newRow[field] = 0;
    });
    const child_rows = orderBy(
      groupedData[keyName],
      ['siteName', 'zoneName', 'assetName', 'groupName'],
      ['asc', 'asc', 'asc', 'asc'],
    );
    groupedData[keyName].forEach((item, index) => {
      if (index === 0) {
        newRow.siteName = item.siteName;
        newRow.zoneName = 'All';
        newRow.assetName = 'All';
        newRow.unit = 'N/A';
        newRow.propertyGroupName = 'All';
      }
      __fieldNames.forEach((field) => {
        if (isValidNumber(item[field])) {
          newRow[field] += item[field];
        }
      });
    });

    newRow.optimalPercentage = round(
      (newRow.optimalCount / newRow.totalCount) * 100,
      1,
    );
    newRow.optimalBelowLowPercentage = round(
      (newRow.optimalBelowLowCount / newRow.totalCount) * 100,
      1,
    );
    newRow.optimalAboveHighPercentage = round(
      (newRow.optimalAboveHighCount / newRow.totalCount) * 100,
      1,
    );
    newRow.incidentBelowLowPercentage = round(
      (newRow.incidentBelowLowCount / newRow.totalCount) * 100,
      1,
    );
    newRow.incidentAboveHighPercentage = round(
      (newRow.incidentAboveHighCount / newRow.totalCount) * 100,
      1,
    );

    if (isNaN(newRow.optimalPercentage)) {
      newRow.optimalPercentage = 0;
    }
    if (isNaN(newRow.optimalBelowLowPercentage)) {
      newRow.optimalBelowLowPercentage = 0;
    }
    if (isNaN(newRow.optimalAboveHighPercentage)) {
      newRow.optimalAboveHighPercentage = 0;
    }
    if (isNaN(newRow.incidentBelowLowPercentage)) {
      newRow.incidentBelowLowPercentage = 0;
    }
    if (isNaN(newRow.incidentAboveHighPercentage)) {
      newRow.incidentAboveHighPercentage = 0;
    }

    newRow.min = 'N/A';
    newRow.max = 'N/A';
    newRow.avg = 'N/A';

    resultData.push(newRow);
    child_rows.forEach((item) => resultData.push(item));
  });

  return resultData;
}

const CustomReportGeneratorModal = React.memo(function ({isOpen, setIsOpen}) {

  const classes = useStyles();
  const {selectedCompany} = useContext(GlobalContext);
  const [createReport] = useMutation(CREATE_CUSTOM_REPORT_MUTATION);

  const [
    fetchRawTelemetryData,
    {data: rawTelemetryData, loading: rawTelemetryDataLoading},
  ] = useLazyQuery(GET_ASSET_RAW_TELEMETRY_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [
    fetchRawVehicleTelemetryData,
    { loading: rawVehicleTelemetryDataLoading},
  ] = useLazyQuery(GET_ASSET_VEHICLE_RAW_TELEMETRY_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [
    fetchRawSiteSummaryTelemetryData,
    {
      data: rawSiteSummaryTelemetryData,
      loading: rawSiteSummaryTelemetryDataLoading,
    },
  ] = useLazyQuery(GET_SITE_SUMMARY_TELEMETRY_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [
    fetchSiteAssetSummaryPerformanceCsvData,
    {
      data: siteAssetSummaryPerformanceCsvData,
      loading: siteAssetSummaryPerformanceCsvDataLoading,
    },
  ] = useLazyQuery(GET_SITE_ASSET_SUMMARY_PERFORMANCE_TELEMETRY_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [
    fetchSiteAssetPerformanceCsvData,
    {data: sitePerformanceCsvData, loading: sitePerformanceCsvDataLoading},
  ] = useLazyQuery(GET_SITE_PERFORMANCE_CSV_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const {
    fetchData: getCompanyIncidentCsvData,
    data: companyIncidentCsvData,
    progress: companyIncidentCsvDataProgress,
    loading: companyIncidentCsvDataLoading,
  } = useProgressiveDataLoader({
    query: GET_INCIDENTS_CSV_QUERY,
    isImmediate: false,
    queryOption: {
      fetchPolicy: 'no-cache',
    },
  });

  const [
    {
      submissionCompleted,
      downloadFileObject,
      exportLoadProgress,
      allRawTelemetryData,
      allVehicleRawTelemetryData,
    },
    setState,
  ] = useReducer(commonReducer, {
    isFromTimeOpen: false,
    isToTimeOpen: false,
    submissionCompleted: false,
    allRawTelemetryData: undefined,
    allVehicleRawTelemetryData: undefined,
    exportLoadProgress: undefined,
    downloadFileObject: undefined,
  });

  const processCreateNewReports = useCallback(
    async function processCreateNewReports(setSubmitting, finalData) {
      setSubmitting(true);
      if (!finalData.companyId) {
        // eslint-disable-next-line no-param-reassign
        finalData.companyId = selectedCompany?.id;
      }
      const result = await createReport({variables: finalData});
      setSubmitting(false);
      if (result && result.data && Object.values(result.data)[0]) {
        if (Object.values(result.data)[0].success === true) {
          toast('Your custom export request has been submitted successfully', {
            type: 'success',
          });
          setState({
            exportLoadProgress: undefined,
            submissionCompleted: true,
          });
        } else {
          const errorMessage =
            Object.values(result.data)[0] &&
            Object.values(result.data)[0].message
              ? Object.values(result.data)[0].message
              : 'Failed Request';
          toast(errorMessage, {
            type: 'error',
          });
          setState({
            exportLoadProgress: undefined,
          });
        }
      }
    },
    [createReport, selectedCompany],
  );

  const formikOptions = useFormik({
    enableReinitialize: false,
    validationSchema: ValidationSchema,
    initialValues: {
      companyId: selectedCompany ? selectedCompany.id : undefined,
      from: dateToEpochString(subDays(new Date(), 1)),
      to: dateToEpochString(new Date()),
      interval: '10m',
    },
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      const finalData = {
        reportType: values.reportType,
        companyIds: [values.companyId],
        from: parseFloat(values.from.toString()),
        to: parseFloat(values.to.toString()),
        siteIds: values.siteId ?? [],
        zoneIds: values.zoneId ?? [],
        assetIds: values.assetId ?? [],
      };

      setState({
        allRawTelemetryData: undefined,
        allVehicleRawTelemetryData: undefined,
        exportLoadProgress:
          finalData.reportType === 'RAW_TELEMETRY_CSV' || finalData.reportType === 'RAW_VEHICLE_TELEMETRY_CSV' ? 1 : undefined,
      });

      try {
        if (finalData.reportType === 'RAW_TELEMETRY_CSV') {
          fetchRawTelemetryExportData(1);
        } else if (finalData.reportType === 'RAW_VEHICLE_TELEMETRY_CSV') {
          fetchRawVehicleTelemetryExportData();
        } else if (finalData.reportType === 'SITE_SUMMARY_CSV') {
          fetchSiteSummaryTelemetryExportData();
        } else if (finalData.reportType === 'ASSET_SUMMARY_PERFORMANCE_CSV') {
          fetchSiteAssetSummaryPerformanceTelemetryExportData();
        } else if (finalData.reportType === 'SITE_PERFORMANCE_CSV') {
          fetchSitePerformanceCsvData();
        } else if (finalData.reportType === 'COMPANY_INCIDENT_CSV') {
          fetchCompanyIncidentCsvData();
        } else {
          await processCreateNewReports(setSubmitting, finalData, resetForm);
        }
      } catch (err) {
        console.log(err);
        if (err && err.message) {
          toast(err.message, {
            type: 'error',
          });
        }
      }
    },
  });

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = formikOptions;

  var fetchRawTelemetryExportData = useCallback(
    (page = 1) => {
      const {assetId, from, to} = values;
      if (assetId && from && to) {
        fetchRawTelemetryData({
          variables: {
            companyId: selectedCompany?.id,
            page,
            assetIds: assetId,
            from: from.toString(),
            to: to.toString(),
          },
        });
      }
    },
    [fetchRawTelemetryData, selectedCompany, values],
  );


  var fetchRawVehicleTelemetryExportData = useCallback(
    async () => {
      const {assetId, from, to, interval} = values;
      if (assetId && from && to) {
        try {
          const result = await fetchRawVehicleTelemetryData({
            variables: {
              companyId: selectedCompany?.id,
              assetIds: assetId,
              from: from.toString(),
              to: to.toString(),
              interval,
            },
          });
          if (result && result.data && result.data.assetVehicleTelemetryRawData) {
            const {
              success,
              message,
              nodes
            } = result.data.assetVehicleTelemetryRawData;

            if (success) {
              setState({
                allVehicleRawTelemetryData: nodes,
                exportLoadProgress: 100,
                lastUUID: v1(),
              });
            } else {
              toast.error(message || "Request failed");
              setState({
                exportLoadProgress: undefined,
              });
            }

          }
        } catch (err) {
          console.error(err);
          setState({
            exportLoadProgress: undefined,
            lastUUID: v1(),
          });
          toast.error(err.message || "Request failed");
        }
      }
    },
    [fetchRawVehicleTelemetryData, selectedCompany, values],
  );

  var fetchSiteSummaryTelemetryExportData = useCallback(() => {
    const {zoneId, from, to} = values;
    if (zoneId && zoneId.length > 0 && from && to) {
      setState({
        exportLoadProgress: 20,
      });
      try {
        fetchRawSiteSummaryTelemetryData({
          variables: {
            companyId: selectedCompany?.id,
            zoneIds: zoneId,
            from: from.toString(),
            to: to.toString(),
          },
        });
      } catch (err) {
        console.log(err);
        setState({
          exportLoadProgress: undefined,
        });
      }
    }
  }, [fetchRawSiteSummaryTelemetryData, selectedCompany, values]);

  var fetchSiteAssetSummaryPerformanceTelemetryExportData = useCallback(() => {
    const {zoneId, from, to} = values;
    if (zoneId && zoneId.length > 0 && from && to) {
      setState({
        exportLoadProgress: 20,
      });
      try {
        fetchSiteAssetSummaryPerformanceCsvData({
          variables: {
            companyId: selectedCompany?.id,
            zoneIds: zoneId,
            from: from.toString(),
            to: to.toString(),
          },
        });
      } catch (err) {
        console.log(err);
        setState({
          exportLoadProgress: undefined,
        });
      }
    }
  }, [fetchSiteAssetSummaryPerformanceCsvData, selectedCompany, values]);

  var fetchSitePerformanceCsvData = useCallback(() => {
    const {zoneId, from, to} = values;
    if (zoneId && zoneId.length > 0 && from && to) {
      setState({
        exportLoadProgress: 20,
      });
      try {
        fetchSiteAssetPerformanceCsvData({
          variables: {
            companyId: selectedCompany?.id,
            zoneIds: zoneId,
            from: from.toString(),
            to: to.toString(),
          },
        });
      } catch (err) {
        console.log(err);
        setState({
          exportLoadProgress: undefined,
        });
      }
    }
  }, [fetchSiteAssetPerformanceCsvData, selectedCompany, values]);

  const fetchCompanyIncidentCsvData = useCallback(() => {
    const {from, to} = values;
    if (from && to) {
      try {
        setState({
          exportLoadProgress: 1,
        });
        getCompanyIncidentCsvData({
          variables: {
            companyId: selectedCompany?.id,
            page: 1,
            from: from.toString(),
            to: to.toString(),
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [getCompanyIncidentCsvData, selectedCompany, values]);

  useEffect(() => {
    if (rawTelemetryData?.assetTelemetryRawData) {
      const {page, pages, nodes} = rawTelemetryData?.assetTelemetryRawData;
      if (page === 1) {
        setState({
          allRawTelemetryData: [...nodes],
          exportLoadProgress: page < pages ? (page / pages) * 100 : 100,
          lastUUID: v1(),
        });
      } else {
        const updatedNodes = [...allRawTelemetryData, ...nodes];
        setState({
          allRawTelemetryData: updatedNodes,
          exportLoadProgress: page < pages ? (page / pages) * 100 : 100,
          lastUUID: v1(),
        });
      }
      if (page < pages) {
        fetchRawTelemetryExportData(page + 1);
      }
    }
    // eslint-disable-next-line
  }, [rawTelemetryData]);


  const parseTelemetryDataIntoCsvFile = useCallback(() => {

    if (allRawTelemetryData && Array.isArray(allRawTelemetryData)) {
      const {from, to} = values;
      // "assetName", "deviceName", "groupName", "propertyName",
      //  "asc", "asc", "asc", "asc"
      const allData = orderBy(allRawTelemetryData, ['time'], ['asc']);
      const downloadOptions = {
        filename: `RAW_TELEMETRY_${format(
          new Date(parseInt(from) * 1000),
          'dd/MM/yyyy_HH:mm:ss',
          {awareOfUnicodeTokens: true},
        )}_to_${format(new Date(parseInt(to) * 1000), 'dd/MM/yyyy_HH:mm:ss', {
          awareOfUnicodeTokens: true,
        })}.csv`,
        separator: ',',
      };

      const fieldNames = [
        {name: 'assetName', label: 'Asset'},
        {name: 'deviceName', label: 'Device'},
        {name: 'deviceSerial', label: 'Serial No'},
        {name: 'propertyName', label: 'Property'},
        {name: 'groupName', label: 'Group'},
        {name: 'value', label: 'Value'},
        {name: 'unit', label: 'Unit'},
        {name: 'time', label: 'Time'},
      ];

      const _downloadFileObject = CsvDownLoader(
        fieldNames.map((item) => {
          return {
            name: item.name,
            label: item.label,
            download: true,
          };
        }),
        allData.map((item) => {
          return {
            data: {
              ...item,
              time: item.time
                ? format(new Date(item.time), 'dd/MM/yyyy HH:mm:ss', {
                  awareOfUnicodeTokens: true,
                })
                : '',
            },
          };
        }),
        {downloadOptions},
        true,
      );

      setState({
        exportLoadProgress: undefined,
        downloadFileObject: _downloadFileObject,
        allRawTelemetryData: undefined,
      });
    }
  }, [allRawTelemetryData, values]);


  const parseVehicleTelemetryDataIntoCsvFile = useCallback(() => {
    if (allVehicleRawTelemetryData && Array.isArray(allVehicleRawTelemetryData)) {
      let propKeys = [];
      const {from, to} = values;
      let allData = [];
      const groupedDataByAsset = groupBy(allVehicleRawTelemetryData, 'assetName');
      const keys = Object.keys(groupedDataByAsset).sort();
      for (const key of keys) {
        const keyData = groupedDataByAsset[key];
        const dataByTimeMap = keyBy(keyData, 'time');
        const dataByTimeGroup = groupBy(keyData, 'time');
        const timeKeys = Object.keys(dataByTimeMap);
        const groupedByProperty = groupBy(keyData, 'propertyName');
        propKeys = Object.keys(groupedByProperty).sort();
        const timeMap = {};
        for (const key of timeKeys) {
          const refObj = dataByTimeMap[key];
          const newObj = {
            time: refObj.time,
            assetName: refObj.assetName,
            address: refObj.address
          };
          const groupedByTimeData = dataByTimeGroup[key];
          const innerDataByProps = keyBy(groupedByTimeData, 'propertyName');
          for (const propKey of propKeys) {
            if (innerDataByProps[propKey]) {
              newObj[propKey] = innerDataByProps[propKey].value;
            } else {
              newObj[propKey] = "";
            }
          }
          timeMap[key] = newObj;
        }
        allData = orderBy(Object.values(timeMap), ['time'], ['asc']);
      }


      const downloadOptions = {
        filename: `RAW_VEHICLE_TELEMETRY_${format(
          new Date(parseInt(from) * 1000),
          'dd/MM/yyyy_HH:mm:ss',
          {awareOfUnicodeTokens: true},
        )}_to_${format(new Date(parseInt(to) * 1000), 'dd/MM/yyyy_HH:mm:ss', {
          awareOfUnicodeTokens: true,
        })}.csv`,
        separator: ',',
      };
      let fieldNames = [
        {name: 'assetName', label: 'Asset'},
        {name: 'time', label: 'Time'},
        {name: 'address', label: 'Address'},
      ];

      for (const key of propKeys) {
        fieldNames.push({
          name: key,
          label: upperFirst(key),
        })
      }

      const _downloadFileObject = CsvDownLoader(
        fieldNames.map((item) => {
          return {
            name: item.name,
            label: item.label,
            download: true,
          };
        }),
        allData.map((item) => {
          return {
            data: {
              ...item,
              time: item.time
                ? format(new Date(item.time), 'dd/MM/yyyy HH:mm:ss', {
                  awareOfUnicodeTokens: true,
                })
                : '',
            },
          };
        }),
        {downloadOptions},
        true,
      );
      setState({
        exportLoadProgress: undefined,
        downloadFileObject: _downloadFileObject,
        allV: undefined,
      });
    }
  }, [allVehicleRawTelemetryData, values]);

  const parseSiteSummaryTelemetryDataIntoCsvFile = useCallback(
    (data) => {
      if (data) {
        const {from, to} = values;
        const allData = orderBy(
          data,
          ['assetName', 'deviceName', 'groupName', 'propertyName'],
          ['asc', 'asc', 'asc', 'asc'],
        );
        const downloadOptions = {
          filename: `SITE_SUMMARY_${format(
            new Date(parseInt(from) * 1000),
            'dd/MM/yyyy_HH:mm:ss',
            {awareOfUnicodeTokens: true},
          )}_to_${format(new Date(parseInt(to) * 1000), 'dd/MM/yyyy_HH:mm:ss', {
            awareOfUnicodeTokens: true,
          })}.csv`,
          separator: ',',
        };
        const fieldNames = [
          {name: 'assetName', label: 'Asset'},
          {name: 'deviceName', label: 'Device'},
          {name: 'deviceSerial', label: 'Serial No'},
          {name: 'propertyName', label: 'Property'},
          {name: 'propertyGroupName', label: 'Group'},
          {name: 'min', label: 'Min'},
          {name: 'avg', label: 'Average'},
          {name: 'max', label: 'Max'},
          {name: 'unit', label: 'Unit'},
        ];

        const _downloadFileObject = CsvDownLoader(
          fieldNames.map((item) => {
            return {
              name: item.name,
              label: item.label,
              download: true,
            };
          }),
          allData.map((item) => {
            return {
              data: {
                ...item,
              },
            };
          }),
          {downloadOptions},
          true,
        );

        setState({
          exportLoadProgress: undefined,
          downloadFileObject: _downloadFileObject,
          allRawTelemetryData: undefined,
        });
      }
    },
    [values],
  );

  const parseSiteSummaryPerformanceTelemetryDataIntoCsvFile = useCallback(
    (data) => {
      if (data) {
        const {from, to} = values;
        const allData = prepareAssetSummaryPerformanceDataBySiteName(
          prepareAssetSummaryPerformanceData(data),
        );
        const downloadOptions = {
          filename: `ASSET_SUMMARY_PERFORMANCE_${format(
            new Date(parseInt(from) * 1000),
            'dd/MM/yyyy_HH:mm',
            {awareOfUnicodeTokens: true},
          )}_to_${format(new Date(parseInt(to) * 1000), 'dd/MM/yyyy_HH:mm', {
            awareOfUnicodeTokens: true,
          })}.csv`,
          separator: ',',
        };
        const fieldNames = [
          {name: 'siteName', label: 'Site'},
          {name: 'zoneName', label: 'Zone'},
          {name: 'assetName', label: 'Asset'},
          {name: 'propertyGroupName', label: 'Group'},
          {name: 'incidentBelowLowPercentage', label: 'Low (%)'},
          {name: 'optimalBelowLowPercentage', label: 'Below Optimal (%)'},
          {name: 'optimalPercentage', label: 'Optimal (%)'},
          {name: 'optimalAboveHighPercentage', label: 'Above Optimal (%)'},
          {name: 'incidentAboveHighPercentage', label: 'High (%)'},
          {name: 'min', label: 'Min'},
          {name: 'max', label: 'Max'},
          {name: 'avg', label: 'Average'},
          {name: 'unit', label: 'Unit'},
        ];

        const _downloadFileObject = CsvDownLoader(
          fieldNames.map((item) => {
            return {
              name: item.name,
              label: item.label,
              download: true,
            };
          }),
          allData.map((item) => {
            return {
              data: {
                ...item,
              },
            };
          }),
          {downloadOptions},
          true,
        );

        setState({
          exportLoadProgress: undefined,
          downloadFileObject: _downloadFileObject,
          allRawTelemetryData: undefined,
        });
      }
    },
    [values],
  );

  const parseSitePerformanceTelemetryDataIntoCsvFile = useCallback(
    (data) => {
      if (data) {
        const {from, to} = values;
        let allData = mergePerformances(data, 'siteName');
        allData = sortBy(allData, 'siteName');
        const downloadOptions = {
          filename: `SITE_PERFORMANCE_${format(
            new Date(parseInt(from) * 1000),
            'dd/MM/yyyy_HH:mm',
            {awareOfUnicodeTokens: true},
          )}_to_${format(new Date(parseInt(to) * 1000), 'dd/MM/yyyy_HH:mm', {
            awareOfUnicodeTokens: true,
          })}.csv`,
          separator: ',',
        };
        const fieldNames = [
          {name: 'siteName', label: 'Site'},
          {name: 'incidentBelowLowPercentage', label: 'Low (%)'},
          {name: 'optimalBelowLowPercentage', label: 'Below Optimal (%)'},
          {name: 'optimalPercentage', label: 'Optimal (%)'},
          {name: 'optimalAboveHighPercentage', label: 'Above Optimal (%)'},
          {name: 'incidentAboveHighPercentage', label: 'High (%)'},
        ];

        const _downloadFileObject = CsvDownLoader(
          fieldNames.map((item) => {
            return {
              name: item.name,
              label: item.label,
              download: true,
            };
          }),
          allData.map((item) => {
            return {
              data: {
                ...item,
              },
            };
          }),
          {downloadOptions},
          true,
        );

        setState({
          exportLoadProgress: undefined,
          downloadFileObject: _downloadFileObject,
          allRawTelemetryData: undefined,
        });
      }
    },
    [values],
  );

  const parseCompanyIncidentDataIntoCsvFile = useCallback(
    (data) => {
      if (data) {
        const {from, to} = values;
        const allData = orderBy(data, ['createdAt'], ['asc']);
        const downloadOptions = {
          filename: `INCIDENT_SUMMARY_${format(
            new Date(parseInt(from) * 1000),
            'dd/MM/yyyy_HH:mm:ss',
            {awareOfUnicodeTokens: true},
          )}_to_${format(new Date(parseInt(to) * 1000), 'dd/MM/yyyy_HH:mm:ss', {
            awareOfUnicodeTokens: true,
          })}.csv`,
          separator: ',',
        };
        const fieldNames = [
          {name: 'id', label: 'ID'},
          {name: 'assetName', label: 'Asset'},
          {name: 'propertyGroupName', label: 'Group'},
          {name: 'siteName', label: 'Site'},
          {name: 'zoneName', label: 'Zone'},
          {name: 'type', label: 'Type'},
          {name: 'worstVal', label: 'Worst'},
          {name: 'duration', label: 'Duration'},
          {name: 'start', label: 'Start'},
          {name: 'end', label: 'End'},
          {name: 'acknowledgedBy', label: 'Acknowledged'},
          {name: 'acknowledgedAt', label: 'Acknowledged At'},
          {name: 'timeToAcknowledge', label: 'Time to Acknowledge'},
          {name: 'status', label: 'State'},
          {name: 'rootCauseDescription', label: 'Root Cause'},
          {name: 'rootCauseType', label: 'Root Cause Type'},
          {name: 'rootCauseUserName', label: 'User'},
          {name: 'rootCauseTime', label: 'Time'},
          {name: 'correctiveDescription', label: 'Corrective Action'},
          {name: 'correctiveUserName', label: 'User'},
          {name: 'correctiveTime', label: 'Time'},
          {name: 'preventiveDescription', label: 'Preventive Action'},
          {name: 'preventiveUserName', label: 'User'},
          {name: 'preventiveTime', label: 'Time'},
        ];

        const _downloadFileObject = CsvDownLoader(
          fieldNames.map((item) => {
            return {
              name: item.name,
              label: item.label,
              download: true,
            };
          }),
          allData.map((item) => {
            const {
              start,
              end,
              worstVal,
              duration,
              acknowledgedBy,
              acknowledgedAt,
              timeToAcknowledge,
              rootCauseDescription,
              rootCauseType,
              rootCauseUserName,
              rootCauseTime,
              correctiveDescription,
              correctiveActionType,
              correctiveUserName,
              correctiveTime,
              preventiveDescription,
              preventiveActionType,
              preventiveUserName,
              preventiveTime,
            } = transformIncidentCsvRow(item);

            return {
              data: {
                ...item,
                siteName: item.asset?.zone?.site?.name,
                zoneName: item.asset?.zone?.name,
                type: upperFirst(item.type),
                worstVal,
                duration,
                start,
                end,
                acknowledgedBy,
                acknowledgedAt,
                timeToAcknowledge,
                status: upperFirst(item.status),
                rootCauseDescription,
                rootCauseType,
                rootCauseUserName,
                rootCauseTime,
                correctiveDescription,
                correctiveActionType,
                correctiveUserName,
                correctiveTime,
                preventiveDescription,
                preventiveActionType,
                preventiveUserName,
                preventiveTime,
              },
            };
          }),
          {downloadOptions},
          true,
        );

        setState({
          exportLoadProgress: undefined,
          downloadFileObject: _downloadFileObject,
          allRawTelemetryData: undefined,
        });
      }
    },
    [values],
  );

  useEffect(() => {
    if (
      rawSiteSummaryTelemetryData &&
      rawSiteSummaryTelemetryData.siteSummaryCsvData
    ) {
      parseSiteSummaryTelemetryDataIntoCsvFile(
        rawSiteSummaryTelemetryData.siteSummaryCsvData,
      );
    }
    // eslint-disable-next-line
  }, [rawSiteSummaryTelemetryData]);

  useEffect(() => {
    if (
      siteAssetSummaryPerformanceCsvData &&
      siteAssetSummaryPerformanceCsvData.siteAssetSummaryPerformanceCsvData
    ) {
      parseSiteSummaryPerformanceTelemetryDataIntoCsvFile(
        siteAssetSummaryPerformanceCsvData.siteAssetSummaryPerformanceCsvData,
      );
    }
    // eslint-disable-next-line
  }, [siteAssetSummaryPerformanceCsvData]);

  useEffect(() => {
    if (
      sitePerformanceCsvData &&
      sitePerformanceCsvData.sitePerformanceCsvData
    ) {
      parseSitePerformanceTelemetryDataIntoCsvFile(
        sitePerformanceCsvData.sitePerformanceCsvData,
      );
    }
    // eslint-disable-next-line
  }, [sitePerformanceCsvData]);

  useEffect(() => {
    if (exportLoadProgress === 100 && allRawTelemetryData) {
      parseTelemetryDataIntoCsvFile();
    }
    // eslint-disable-next-line
  }, [exportLoadProgress, allRawTelemetryData]);


  useEffect(() => {
    if (exportLoadProgress === 100 && allVehicleRawTelemetryData) {
      parseVehicleTelemetryDataIntoCsvFile();
    }
    // eslint-disable-next-line
  }, [exportLoadProgress, allVehicleRawTelemetryData]);

  const shouldShowFormControls = useCallback(() => {
    return (
      !submissionCompleted &&
      !downloadFileObject &&
      !exportLoadProgress &&
      (!companyIncidentCsvDataLoading || !companyIncidentCsvDataProgress)
    );
  }, [
    submissionCompleted,
    downloadFileObject,
    exportLoadProgress,
    companyIncidentCsvDataProgress,
    companyIncidentCsvDataLoading,
  ]);

  const shouldShowReportSubmissionSuccessBlock = useCallback(() => {
    return submissionCompleted;
  }, [submissionCompleted]);

  const shouldShowExportFileSubmissionSuccessBlock = useCallback(() => {
    if (downloadFileObject && downloadFileObject.uri) {
      return true;
    }
    if (exportLoadProgress > 0) {
      return true;
    }
    return false;
  }, [downloadFileObject, exportLoadProgress]);

  const parseIntoDate = useCallback(
    (val) => new Date(timeHelper.parseInterval(val)),
    [],
  );

  useEffect(() => {
    if (companyIncidentCsvDataProgress > 0) {
      setState({
        exportLoadProgress: companyIncidentCsvDataProgress,
      });
    }
  }, [companyIncidentCsvDataProgress]);

  useEffect(() => {
    if (companyIncidentCsvData) {
      parseCompanyIncidentDataIntoCsvFile(companyIncidentCsvData);
    }
    //eslint-disable-next-line
  }, [companyIncidentCsvData]);

  return (
    <Modal size="lg" isOpen={isOpen}>
      <Formik {...formikOptions}>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <h2 className={classes.blueText}>Custom export</h2>
            {shouldShowExportFileSubmissionSuccessBlock() && (
              <div className={classes.submissionCompleteContainer}>
                {!downloadFileObject && exportLoadProgress > 0 && (
                  <React.Fragment>
                    <Row className="margin-top-10">
                      <Col>
                        <h2 className={classes.blueText}>Exporting data</h2>
                      </Col>
                    </Row>
                    <Row className="margin-top-10">
                      <Col>
                        <div className={classes.progressContainer}>
                          <Progress
                            animated
                            color="primary"
                            value={exportLoadProgress}
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
                {downloadFileObject?.uri && (
                  <React.Fragment>
                    <Row className="margin-top-10">
                      <Col>
                        <i
                          style={{fontSize: '3em'}}
                          className="icon check circle green"
                        />
                      </Col>
                    </Row>
                    <Row className="margin-top-10">
                      <Col>
                        <h2 className={classes.blueText}>Completed</h2>
                      </Col>
                    </Row>
                    <Row className="margin-top-10">
                      <Col>
                        <h4 className={classes.blueText}>
                          Please click the following link to download the file.
                        </h4>
                      </Col>
                    </Row>
                    <Row className="margin-top-10">
                      <Col>
                        <h2>
                          <a
                            className={classes.blueText}
                            href={downloadFileObject.uri}
                            download={downloadFileObject.name}
                            name={downloadFileObject.name}
                          >
                            <span>
                              <i className="icon file excel"/>{' '}
                              {downloadFileObject.name}
                            </span>
                          </a>
                        </h2>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
                <Row>
                  <Col>
                    <Button
                      className="margin-top-10"
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setState({
                          submissionCompleted: false,
                          downloadFileObject: undefined,
                        });
                        resetForm();
                      }}
                    >
                      Start over{' '}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            {shouldShowReportSubmissionSuccessBlock() && (
              <div className={classes.submissionCompleteContainer}>
                <Row className="margin-top-10">
                  <Col>
                    <i
                      style={{fontSize: '3em'}}
                      className="icon check circle green"
                    />
                  </Col>
                </Row>
                <Row className="margin-top-10">
                  <Col>
                    <h2 className={classes.blueText}>Completed</h2>
                  </Col>
                </Row>
                <Row className="margin-top-10">
                  <Col>
                    <h4>You will receive email when the export is ready.</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="margin-top-10"
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setState({
                          submissionCompleted: false,
                          downloadFileObject: undefined,
                        });
                        resetForm();
                      }}
                    >
                      Start over{' '}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            {shouldShowFormControls() && (
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="reportType">Report Name</Label>
                        <ExportReportSelector
                          name="reportType"
                          value={
                            !isUndefined(values.reportType)
                              ? values.reportType
                              : ''
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.reportType}
                        />
                        <FormFeedback>{errors.reportType}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>From</Label>
                      <DatePicker
                        includeTime
                        timeInputProps={{use12HourClock: true}}
                        value={parseIntoDate(values.from)}
                        max={parseIntoDate(values.to)}
                        onChange={(value) => {
                          if (isDate(value)) {
                            setFieldValue('from', value.getTime() / 1000);
                          } else {
                            setFieldValue('from', undefined);
                          }
                        }}
                      />
                      {errors.from && (
                        <FormFeedback style={{display: 'block'}}>
                          {errors.from}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>
                  <Row className="margin-top-10">
                    <Col>
                      <Label>To</Label>
                      <DatePicker
                        includeTime
                        timeInputProps={{use12HourClock: true}}
                        value={parseIntoDate(values.to)}
                        min={parseIntoDate(values.from)}
                        onChange={(value) => {
                          if (isDate(value)) {
                            setFieldValue('to', value.getTime() / 1000);
                          } else {
                            setFieldValue('to', undefined);
                          }
                        }}
                      />
                      {errors.to && (
                        <FormFeedback style={{display: 'block'}}>
                          {errors.to}
                        </FormFeedback>
                      )}
                    </Col>
                  </Row>
                  {values.reportType === 'RAW_VEHICLE_TELEMETRY_CSV' ? (
                    <Row className="margin-top-10">
                      <FormGroup>
                        <Label for="interval">Interval</Label>
                        <TimeIntervalSelector
                          name="interval"
                          value={
                            !isUndefined(values.interval)
                              ? values.interval
                              : ''
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.interval}
                        />
                        <FormFeedback>{errors.interval}</FormFeedback>
                      </FormGroup>
                    </Row>
                  ) : null}

                </Col>
                <Col lg={8}>
                  {values.reportType && (
                    <Row>
                      {siteReportType.includes(values.reportType) && (
                        <Col>
                          <FormGroup>
                            <Label for="siteId">Site</Label>
                            {values.companyId && (
                              <SitesTreeSelector
                                companyId={values.companyId}
                                height="70vh"
                                siteId={values.siteId}
                                setFieldValue={setFieldValue}
                              />
                            )}
                            <FormFeedback>{errors.siteId}</FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                      {zoneReportTypes.includes(values.reportType) && (
                        <Col>
                          <FormGroup>
                            <Label>Zones</Label>
                            <SiteZonesTreeSelector
                              companyId={values.companyId}
                              height="70vh"
                              zoneId={values.zoneId}
                              setFieldValue={setFieldValue}
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {assetReportTypes.includes(values.reportType) && (
                        <Col>
                          <FormGroup>
                            <Label>Assets</Label>
                            <SiteZonesAssetsTreeSelector
                              height="70vh"
                              type={
                                values.reportType === 'ASSET_JOURNEY' || values.reportType === 'RAW_VEHICLE_TELEMETRY_CSV'
                                  ? 'Vehicle'
                                  : undefined
                              }
                              companyId={values.companyId}
                              assetId={values.assetId}
                              setFieldValue={setFieldValue}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter className="border-top-0">
            {shouldShowFormControls() && (
              <Button
                color="primary"
                type="submit"
                disabled={
                  isSubmitting ||
                  rawTelemetryDataLoading ||
                  rawVehicleTelemetryDataLoading ||
                  rawSiteSummaryTelemetryDataLoading ||
                  siteAssetSummaryPerformanceCsvDataLoading ||
                  sitePerformanceCsvDataLoading ||
                  companyIncidentCsvDataLoading ||
                  (exportLoadProgress >= 0 && exportLoadProgress < 100)
                }
              >
                {!isSubmitting &&
                  !rawTelemetryDataLoading &&
                  !rawSiteSummaryTelemetryDataLoading &&
                  !rawVehicleTelemetryDataLoading &&
                  !siteAssetSummaryPerformanceCsvDataLoading &&
                  !sitePerformanceCsvDataLoading &&
                  !companyIncidentCsvDataLoading &&
                  (!exportLoadProgress || exportLoadProgress === 100) && (
                    <span>Submit</span>
                  )}
                {(isSubmitting ||
                  rawTelemetryDataLoading ||
                  rawSiteSummaryTelemetryDataLoading ||
                  rawVehicleTelemetryDataLoading ||
                  siteAssetSummaryPerformanceCsvDataLoading ||
                  sitePerformanceCsvDataLoading ||
                  companyIncidentCsvDataLoading) && (
                  <span>
                    Submitting
                    <TinySpinner/>
                  </span>
                )}
              </Button>
            )}
            <Button
              color="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Formik>
    </Modal>
  );
});

export default CustomReportGeneratorModal;
