import React from 'react'
import { Input } from 'reactstrap'

const _values = [
  { name: '1m', label: '1m' },
  { name: '5m', label: '5m' },
  { name: '10m', label: '10m' },
  { name: '15m', label: '15m' },
  { name: '20m', label: '20m' },
  { name: '30m', label: '30m' },
  ]

const TimeIntervalSelector = React.memo(({ setFieldValue, ...props }) => {

  return (
    <Input placeholder='Select'
           onChange={props.handleChange}
           onBlur={props.handleBlur}
           type='select' {...props}>
      <option value=''>Select</option>
      {_values.map((_val, index) => {
        return (
          <option key={index} value={_val.name}>
            {_val.label}
          </option>
        )
      })}
    </Input>)
})


export default TimeIntervalSelector
