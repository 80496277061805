import moment from 'moment'
import { isDate, isUndefined } from 'lodash'
import { formatDistance, formatDistanceStrict, addSeconds } from 'date-fns'


function isValidNumber(param) {
  return param !== undefined && param !== null && isFinite(param);
}

function _dateDiff(timeStart, timeEnd) {
  if (timeStart === 0) {
    return 'N/A'
  }
  const start = moment(timeStart)
  const end = moment(timeEnd ? new Date(timeEnd) : new Date())
  const seconds = end.diff(start, 'seconds')
  return _humanizeDuration(seconds, 's')
}

function _dateFormatter(time) {
  if (time) {
    if (isDate(time)) {
      return moment(new Date(time)).format('DD/MM/YYYY h:mm:ss a')
    }
    return moment(new Date(parseInt(time.toString()))).format(
      'DD/MM/YYYY h:mm:ss a'
    )
  }
  return null
}

function _dateFormatterForChartTooltip (time){
  if (time) {
    if (isDate(time)) {
      return moment(new Date(time)).format('dddd, MMM DD, h:mm a')
    }
    return moment(new Date(parseInt(time.toString()))).format(
      'dddd, MMM DD, h:mm a'
    )
  }
  return null
}

function _shortDateFormatter(time) {
  if (time) {
    if (isDate(time)) {
      return moment(new Date(time)).format('DD/MM/YYYY')
    }
    return moment(new Date(parseInt(time.toString()))).format(
      'DD/MM/YYYY'
    )
  }
  return null
}



function _dateHourMinuteFormatter(time) {
  if (time) {
    if (isDate(time)) {
      return moment(new Date(time)).format('h:mm a')
    }
    return moment(new Date(parseInt(time.toString()))).format(
      'h:mm a'
    )
  }
  return null
}

function _hourMinuteToNumber(time) {
  if (time) {
    const dt = moment(time, 'h:mm:ss');
    return dt.hours();
  }
  return time;
}

function _hourMinuteToNumberCeil(time) {
  if (time) {
    const dt = moment(time, 'h:mm:ss');
    return dt.hours();
  }
  return time;
}

function _isStalledTime(time, compareTime = new Date()) {
  if (time) {
    const timeAsDate = new Date(parseInt(time.toString()))
    return moment(compareTime).diff(moment(timeAsDate), 'hours') > 1
  }
  return false
}

function _showAsFormatDistance(time, compareTime = new Date()) {
  if (time) {
    const timeAsDate = new Date(parseInt(time.toString()))
    return formatDistance(timeAsDate, compareTime, {
      addSuffix: true,
      includeSeconds: true,
    })
  }
  return null
}

function _showAsFormatDistanceStrict(time, compareTime = new Date(), unit= 'minute') {
  if (time) {
    const timeAsDate = new Date(parseInt(time.toString()))
    return formatDistanceStrict(timeAsDate, compareTime, {
      unit,
    })
  }
  return null
}


/***
 * Parse "00:05:00" like string into integer second like 300
 * @param timeStr
 * @private
 */
function _parseHourMinSecStringToSecond(timeStr) {
  // eslint-disable-next-line no-unused-vars
  let [ _, hour, min, sec ]=/(\d+):(\d+):(\d+)/.exec(timeStr);
  if(!isUndefined(hour)){
    hour = parseInt(hour);
  }
  if(!isUndefined(min)){
    min = parseInt(min);
  }
  if(!isUndefined(sec)){
    sec = parseInt(sec);
  }
  let parsedSec = sec;
  parsedSec += min * 60;
  parsedSec += hour * 60 * 60;
  return parsedSec;
}



/***
 * Parse 120 like today 12am + 120 sec = 2am but as date
 * @param timeStr
 * @private
 */
function _parseCurrentDateFromSecond(secStr) {
  // eslint-disable-next-line no-unused-vars
  const todate = new Date();
  todate.setHours(0, 0, 0, 0);
  const parsedDate = addSeconds(todate, parseInt(secStr));
  return parsedDate;
}


function _extractTimePartsFromSecond(subInput) {

  const dayFactor = 24 * 3600;
  const hourFactor = 3600;
  const minuteFactor = 60;
  let day, minute, hour, second = 0;
  let inputCache;
  inputCache =  subInput;
  if (subInput >= dayFactor){
    inputCache =  subInput / dayFactor;
    day = parseInt(inputCache);
    inputCache =  subInput % dayFactor;
  }

  if (inputCache >= hourFactor){
    hour = parseInt(inputCache / hourFactor);
    inputCache = inputCache % hourFactor;
  }
  if (inputCache >= minuteFactor){
    minute = parseInt(inputCache / minuteFactor) ;
    inputCache = inputCache % minuteFactor;
  }
  second = parseInt((inputCache % minuteFactor));
  return { day, minute, hour, second };
}

function _formatWaitTimeDistance(parsedSec) {
  if (!parsedSec){
    return 'Immediately';
  }
  const timeNow = new Date();
  const dt = new Date(Date.now() + (parsedSec * 1000));
  return `After ${_showAsFormatDistanceStrict(dt.getTime(), timeNow)}`;
}

function _humanizeDuration(input, units) {
   // units in either s, ms
  let subInput;
  if (units === 's') {
    subInput = input;
  } else if (units === 'ms'){
    subInput = input / 1000;
  }

  const { day, minute, hour, second } = _extractTimePartsFromSecond(subInput);
    let formattedDistance = '';
    if (day > 0){
      formattedDistance += ` ${day}d`
    }
    if (hour > 0){
      formattedDistance += ` ${hour}h`
    }
    if (minute > 0){
      formattedDistance += ` ${minute}m`
    }
    if (!day && !hour && second) {
      formattedDistance += ` ${second}s`
    }
    return formattedDistance;
}


function _humanizeDurationAsLong(input, units) {
  // units in either s, ms
  let subInput;
  if (units === 's') {
    subInput = input;
  } else if (units === 'ms'){
    subInput = input / 1000;
  }

  const { day, minute, hour, second } = _extractTimePartsFromSecond(subInput);
  let formattedDistance = '';
  if (day > 0){
    formattedDistance += ` ${day} days`
  }
  if (hour > 0){
    formattedDistance += ` ${hour} hours`
  }
  if (minute > 0){
    formattedDistance += ` ${minute} minutes`
  }
  if (!day && !hour && second) {
    formattedDistance += ` ${second} seconds`
  }
  return formattedDistance;
}

function checkActiveTab (match, location)  {
  if (!location || !match) return false
  const { pathname } = location
  const { url } = match
  return  pathname && pathname.includes(url);
}



function isIE11 () {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

const dateDiff = _dateDiff
const dateFormatter =_dateFormatter
const dateFormatterForChartTooltip = _dateFormatterForChartTooltip
const isStalledTime = _isStalledTime
const showAsFormatDistance = _showAsFormatDistance
const showAsFormatDistanceStrict = _showAsFormatDistanceStrict
const parseHourMinSecStringToSecond = _parseHourMinSecStringToSecond
const humanizeDuration = _humanizeDuration
const humanizeDurationAsLong = _humanizeDurationAsLong
const dateHourMinuteFormatter = _dateHourMinuteFormatter
const formatWaitTimeDistance = _formatWaitTimeDistance
const hourMinuteToNumber = _hourMinuteToNumber
const hourMinuteToNumberCeil = _hourMinuteToNumberCeil
const extractTimePartsFromSecond = _extractTimePartsFromSecond
const shortDateFormatter = _shortDateFormatter
const parseCurrentDateFromSecond = _parseCurrentDateFromSecond;



// eslint-disable-next-line import/prefer-default-export
export { dateDiff, dateFormatter, isStalledTime,
  showAsFormatDistance, showAsFormatDistanceStrict,
  humanizeDuration, humanizeDurationAsLong,
  dateHourMinuteFormatter, parseHourMinSecStringToSecond, shortDateFormatter,
  hourMinuteToNumber, hourMinuteToNumberCeil, formatWaitTimeDistance,
  dateFormatterForChartTooltip,
  extractTimePartsFromSecond, checkActiveTab, isIE11, parseCurrentDateFromSecond , isValidNumber }
