import { gql } from '@apollo/client'


export const GET_COMPANIES_LOOKUP_QUERY = gql`
  query($page: Int, $sort: [InputSort], $filter: [InputFilter]) {
    companies(page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
      }
    }
  }
`


export const GET_DEVICES_LOOKUP_QUERY = gql`
  query($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    devices(pageSize: 1000, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
      }
    }
  }
`

export const GET_ASSETS_LOOKUP_QUERY = gql`
  query assetsLookup ($page: Int, $siteId: ID, $zoneId: ID $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    assets(pageSize: 1000, page: $page, sort: $sort, siteId: $siteId, zoneId: $zoneId, companyId: $companyId, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        uuid
        name
        type
        company {
          id
          name
        }
      }
    }
  }
`

export const GET_ASSETS_LOOKUP_FOR_SELECTOR_QUERY = gql`
  query($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    assets(pageSize: 1000, page: $page, sort: $sort, companyId: $companyId, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        type
      }
    }
  }
`

export const GET_SITES_LOOKUP_QUERY = gql`
  query($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    sites(pageSize: 1000, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        company {
          id
          name
        }
      }
    }
  }
`

export const GET_SITES_MINIMAL_LOOKUP_QUERY = gql`
  query($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    sites(pageSize: 1000, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
      }
    }
  }
`


export const GET_ZONES_SITES_LOOKUP_QUERY = gql`
  query zoneSites($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    zones(pageSize: 1000, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        site {
          id
          name
        }
      }
    }
  }
`


export const GET_ASSET_ZONES_SITES_LOOKUP_QUERY = gql`
  query assetZoneSites($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    assets(pageSize: 300, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        uuid
        type
        site {
          id
          name
        }
        zone{
          id
          name
        }
      }
    }
  }
`


export const GET_ZONES_LOOKUP_QUERY = gql`
  query($page: Int, $companyId: ID, $siteId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    zones(pageSize: 1000, page: $page, companyId: $companyId, siteId: $siteId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        siteId
      }
    }
  }
`

export const GET_ZONE_LOOKUP_QUERY = gql`
  query($id: ID!) {
    zone(id: $id) {
      id
      name
      type
      company{
        id
        name
      }
      site{
        id
        name
      }
    }
  }
`

export const GET_COMPANY_LOOKUP_QUERY = gql`
  query companyLookup ($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      isLogisticsFeatureEnabled
      isHanwellAdapterEnabled
      isKubeVisionEnabled
      isLorawanEnabled
      geofenceRadius
    }
  }
`

export const GET_COMPANY_INCIDENT_COUNT_LOOKUP_QUERY = gql`
  query companyLookup ($id: ID!) {
    company(id: $id) {
      id
      stats {
        id
        incidents {
          open
          closed
          unAcknowledged
        }
      }
    }
  }
`

export const GET_DEVICE_TYPES_LOOKUP_QUERY = gql`
  query($page: Int, $sort: [InputSort], $filter: [InputFilter]) {
    deviceTypes(pageSize: 1000, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        properties {
          id
          name
          unit
        }
        configurations {
          id
          name
          data
        }
      }
    }
  }
`



export const GET_DEVICE_PROPERTIES_LOOKUP_QUERY = gql`
  query($page: Int, $sort: [InputSort], $filter: [InputFilter]) {
    deviceProperties(pageSize: 1000, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        unit
      }
    }
  }
`

export const GET_DEFAULT_DEVICE_CONFIGURATIONS_LOOKUP_QUERY = gql`
  query($page: Int, $sort: [InputSort], $filter: [InputFilter]) {
    defaultDeviceConfigurations(pageSize: 1000, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        data
      }
    }
  }
`


export const GET_DEVICE_PROPERTY_GROUPS_LOOKUP_QUERY = gql`
  query($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    devicePropertyGroups(pageSize: 1000, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        companyId
      }
    }
  }
`

export const GET_ALL_DEVICE_PROPERTY_GROUPS_LOOKUP_QUERY = gql`
  query($page: Int, $sort: [InputSort], $filter: [InputFilter]) {
    devicePropertyGroups(pageSize: 1000, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        companyId
      }
    }
  }
`


export const GET_AVAILABLE_ASSET_DEVICE_PROPERTIES_LOOKUP_QUERY = gql`
  query($page: Int, $assetId: ID!, $companyId: ID!) {
    availableAssetDeviceProperties(pageSize: 1000, page: $page, assetId: $assetId, companyId: $companyId) {
      count
      pages
      page
      pageSize
      nodes {
        propertyId
        name
        unit
        deviceName
        deviceId
      }
    }
  }
`

export const GET_TELEMETRY_KPI_SETTINGS_LOOKUP_QUERY = gql`
  query telemetryKpiSettings ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    kpiSettings(pageSize: 1000, page: $page, groupName: "telemetry_optimal", companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        groupId
        companyId
        valueLow
        valueHigh
        icon
      }
    }
  }
`

export const GET_VEHICLE_STOP_TIME_KPI_SETTINGS_LOOKUP_QUERY = gql`
  query vehicleStopTimekpiSettings ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    kpiSettings(pageSize: 1000, page: $page, groupName: "vehicle_trip_stop_time", companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        companyId
        groupId
        valueLow
        valueHigh
        icon
      }
    }
  }
`


export const GET_INCIDENT_ACK_TIME_KPI_SETTINGS_LOOKUP_QUERY = gql`
  query incidentAckTimeKpiSettings ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    kpiSettings(pageSize: 1000, page: $page, groupName: "incident_average_ack", companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        groupId
        companyId
        valueLow
        valueHigh
        icon
      }
    }
  }
`


export const GET_TELEMETRY_COMPLETENESS_KPI_SETTINGS_LOOKUP_QUERY = gql`
  query telemetryKpiSettings ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    kpiSettings(pageSize: 1000, page: $page, groupName: "telemetry_completeness", companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        groupId
        companyId
        valueLow
        valueHigh
        icon
      }
    }
  }
`

export const GET_TIME_PROFILE_LOOKUP_QUERY = gql`
  query timeProfiles ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    timeProfiles(pageSize: 1000, page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
      }
    }
  }
`


export const GET_NOTIFICATION_MESSAGE_TYPES_LOOKUP_QUERY = gql`
  query notificationMessageTypes ($page: Int, $sort: [InputSort], $filter: [InputFilter]) {
    notificationMessageTypes(pageSize: 1000, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        label
      }
    }
  }
`

export const GET_USERS_LOOKUP_QUERY = gql `
  query usersLookup ($page: Int, $companyId: ID!,  $siteId: ID,  $zoneId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    usersLookup(pageSize: 1000, page: $page, companyId: $companyId, siteId: $siteId, zoneId: $zoneId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        telephone
      }
    }
  }
`

export const GET_ALL_USERS_LOOKUP_QUERY = gql`
  query allUsersLookup ($page: Int,  $sort: [InputSort], $filter: [InputFilter]) {
    users(pageSize: 1000, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export const GET_USERS_LOOKUP_FOR_SYSTEM_CHECK_QUERY = gql `
  query usersLookupForSystemCheck ($page: Int, $companyId: ID!,  $systemCheckId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    usersLookupForSystemCheck(pageSize: 1000, page: $page, companyId: $companyId, systemCheckId: $systemCheckId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        telephone
      }
    }
  }
`

export const GET_USERS_LOOKUP_FOR_INCIDENT_QUERY = gql `
  query usersLookupForIncident ($page: Int, $companyId: ID!,  $incidentId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    usersLookupForIncident(pageSize: 1000, page: $page, companyId: $companyId, incidentId: $incidentId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        telephone
      }
    }
  }
`

export const GET_PARTICIPANTS_USER_LOOKUP_FOR_INCIDENT_QUERY = gql `
  query usersParticipantsLookupForIncident ($page: Int, $companyId: ID!,  $incidentId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    usersParticipantsLookupForIncident(pageSize: 1000, page: $page, companyId: $companyId, incidentId: $incidentId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        telephone
      }
    }
  }
`


export const GET_DRIVERS_LOOKUP_QUERY = gql`
  query driversLookup ($page: Int, $companyId: ID,  $sort: [InputSort], $filter: [InputFilter]) {
    drivers(pageSize: 1000, companyId: $companyId, page: $page, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        companyId
        telephone
      }
    }
  }
`

export const GET_CONTACTS_LOOKUP_QUERY = gql`
  query contactsLookup ($page: Int, $companyId: ID, $sort: [InputSort], $filter: [InputFilter]) {
    contacts(pageSize: 1000,page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        email
        telephone
      }
    }
  }
`

export const GET_HANWELL_DEVICE_GROUPS_LOOKUP_QUERY = gql`
  query hanwellControlDeviceGroups ($page: Int, $companyId:ID!, $sort: [InputSort], $filter: [InputFilter]) {
    hanwellControlDeviceGroups(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
          id
          groupName
          companyId
      }
    }
  }
`;

export const GET_SYNCABLE_HANWELL_DEVICES_LOOKUP_QUERY = gql`
  query hanwellTypeDevices($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    hanwellTypeDevices(pageSize: 1000, page: $page, companyId: $companyId, emsUnSyncedOnly: true, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        serial
      }
    }
  }
`


export const GET_HANWELL_CONTROL_DEVICES_LOOKUP_QUERY = gql`
  query hanwellControlDevices ($page: Int, $companyId:ID!, $sort: [InputSort], $filter: [InputFilter]) {
    hanwellControlDevices(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
          id
          controlDeviceName
          controlDeviceType
          controlDeviceTypeName
          controlDeviceSerialNo
          cdIpAddress
          cdListeningPortNo
          idGroup
          controlDeviceGroupName
      }
    }
  }
`;

export const GET_USERS_LOOKUP_FOR_KUBE_VISION_FOOTAGE_REQUEST_QUERY = gql `
  query usersLookupForKubeVisionFootageRequest ($page: Int,  $id: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    usersLookupForKubeVisionFootageRequest(pageSize: 1000, page: $page, id: $id, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        firstName
        lastName
        email
        telephone
      }
    }
  }
`

export const GET_INCIDENT_ROOT_CAUSE_TYPES_LOOKUP_QUERY = gql`
  query incidentRootCauseTypes ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    incidentRootCauseTypes(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        companyId
        isCompanyGlobal
      }
    }
  }
`;


export const GET_INCIDENT_CORRECTIVE_ACTION_TYPES_LOOKUP_QUERY = gql`
  query incidentCorrectiveActionTypes ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    incidentCorrectiveActionTypes(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        companyId
        isCompanyGlobal
      }
    }
  }
`;


export const GET_INCIDENT_PREVENTIVE_ACTION_TYPES_LOOKUP_QUERY = gql`
  query incidentPreventiveActionTypes ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    incidentPreventiveActionTypes(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        companyId
        isCompanyGlobal
      }
    }
  }
`;



export const GET_NOTIFICATION_WEBHOOK_LOOKUP_QUERY = gql`
  query notificationWebhooks ($page: Int, $companyId: ID!, $sort: [InputSort], $filter: [InputFilter]) {
    notificationWebhooks(page: $page, companyId: $companyId, sort: $sort, filter: $filter) {
      count
      pages
      page
      pageSize
      nodes {
        id
        name
        url
        secretKey
        payload
        companyId
      }
    }
  }
`